.App {
  text-align: center;
  height: 100%;
  width: 100%;
}

/* Some stupid div the Router inserts */
.App > div {
  width: 100%;
  height: 100%;
}

.Player {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.Player .Face {
  flex: none;
  margin-right: 2vmin;
}

.Face {
  font-family: "TheFreakyFace";
  font-size: 30vmin;
  border: 0.7vmin solid black;
  border-radius: 50%;
  background-color: white;
  width: 24vmin;
  height: 24vmin;
  line-height: 21vmin;
  user-select: none;
  box-shadow: 0.5vmin 0.7vmin 1vmin rgba(0, 0, 0, 0.2);
  text-align: center;
  overflow: hidden;
}

.Face-small {
  width: 16vmin;
  height: 16vmin;
  font-size: 20vmin;
  line-height: 14.2vmin;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 1vmin;
}

.buttons .button {
  margin: 1vmin;
}

.button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 2vmin 4vmin;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 4vmin;
  font-size: 1em;
  box-shadow: 0.5vmin 0.7vmin 1vmin rgba(0, 0, 0, 0.2);
  cursor: pointer;
  outline: none;
}

.button-red {
  background-color: #b00;
}

.button-blue {
  background-color: #3434d1;
}

.button:disabled {
  background-color: #888;
  cursor: not-allowed;
}

.Drawing {
  display: block;
  margin: 2vmin 0;
  max-height: 100vh;
  max-width: 90vw;
}
