.Join {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}

.Join-logo {
  height: 100%;
  flex-grow: 1;
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.Join-logo .Logo {
  flex-grow: 0;
}

.Join-logo .Logo img {
  max-height: 75vh;
  width: 40vw;
}

.Join-content {
  flex-grow: 1;
  flex-basis: 50%;
}

.Join-content .SelectFace {
  margin-top: 0.5vmin;
  margin-bottom: 3vmin;
}

.Join-content label {
  display: block;
  margin-bottom: 0.9vmin;
}

.Join-content input {
  width: 80%;
  margin-bottom: 4vmin;
}

.SelectFace {
  cursor: pointer;
  width: 24vmin;
  height: 24vmin;
  vertical-align: middle;
  margin: 0 auto;
}
