.Decoration {
  font-family: "KidsDrawings";
  font-size: calc(25px + 15vmin);
  flex-grow: 1;
  color: black;
  margin-left: 3vmin;
  margin-right: 3vmin;
  text-shadow: 0.5vmin 0.7vmin 1vmin rgba(61, 37, 37, 0.2);
  cursor: pointer;
  user-select: none;
}
