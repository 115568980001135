html,
body,
#root {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@font-face {
  font-family: "Comic Neue";
  src: url(./fonts/ComicNeue-Regular.woff) format("woff");
}

@font-face {
  font-family: "KidsDrawings";
  src: url(./fonts/KidsDrawings.woff) format("woff");
  font-display: block;
}

@font-face {
  font-family: "TheFreakyFace";
  src: url(./fonts/TheFreakyFace.woff) format("woff");
  font-display: block;
}

body {
  background-color: #ffffbe;
}

body,
button,
input,
textarea {
  font-family: "Comic Neue", sans-serif;
  font-size: calc(14px + 3vmin);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
}

a {
  color: black;
}

h1 {
  font-size: calc(16px + 4vmin);
  font-weight: bold;
  margin: 1vmin 0;
}

input,
textarea,
.field {
  outline: none;
  border: 0.7vmin solid black;
  border-radius: 2vmin;
  padding: 1vmin 2vmin;
  background-color: white;
  box-shadow: 0.5vmin 0.7vmin 1vmin rgba(0, 0, 0, 0.2);
  text-align: center;
}

.field {
  word-break: break-word;
  overflow-wrap: anywhere;
}

.field-label {
  font-size: 0.7em;
}

.hidden {
  display: none !important;
}

.small {
  font-size: 0.7em;
}
