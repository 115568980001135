.Draw {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.Draw-tools {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Draw-tools .tool-color {
  margin: 2vmin;
}

.Draw-tools > .tool-button {
  margin: 2vmin;
}

.Draw-tools .tool-button-done {
  margin: 2vmin;
  margin-top: 5vmin;
}

.Draw-canvas {
  flex-grow: 1;
}

.Draw-canvas canvas {
  touch-action: none;
  display: block;
  max-width: 100%;
  max-height: 100vh;
  cursor: crosshair;
  border-radius: 2vmin;
}

.tool-button {
  background-color: rgb(40, 27, 219);
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 50%;
  font-size: 0.7em;
  box-shadow: 0.5vmin 0.7vmin 1vmin rgba(0, 0, 0, 0.2);
  cursor: pointer;
  outline: none;
  width: 15vmin;
  height: 15vmin;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tool-button-help {
  background-color: #b00;
}

.tool-button-done {
  background-color: #4caf50;
}

.tool-button img {
  width: 7vmin;
  height: 7vmin;
  filter: invert();
}

.tool-button-brush {
  cursor: pointer;
  margin: 1vmin;
}

.tool-button-brush > div {
  background-color: black;
  border-radius: 50%;
}

.tool-popup {
  position: absolute;
  margin-left: 2vmin;
  padding: 1vmin;
  border-radius: 4vmin;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.tool-color {
  width: 15vmin;
  height: 15vmin;
  position: relative;
  border-radius: 50%;
  box-shadow: 0.5vmin 0.7vmin 1vmin rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.tool-color .tool-color-selectedcolor {
  position: absolute;
  width: 13vmin;
  height: 13vmin;
  left: 1vmin;
  top: 1vmin;
  border-radius: 50%;
  z-index: -1;
}

.tool-color img {
  width: 15vmin;
  height: 15vmin;
}

.ColorPicker {
  width: 100%;
  height: 100%;
  display: flex;
}

.ColorPicker > div {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.ColorPicker > div > div {
  flex-grow: 1;
  cursor: pointer;
}

.DrawHelp {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.DrawHelp h1 img {
  height: 14vmin;
  margin-top: 2.3vmin;
}
